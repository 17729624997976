var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('fragment',[_c('ax-table-row-actions-column',[(_vm.canModifyPolicy)?_c('v-list-item',{on:{"click":function($event){return _vm.openModal(_vm.policy, 'associateGroups')}}},[_c('v-list-item-title',[_vm._v("Associate Groups")])],1):_vm._e(),(_vm.isPatchPolicy && _vm.canModifyPolicy)?_c('v-list-item',{on:{"click":function($event){return _vm.openModal(_vm.policy, 'toggleUsage')}}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.policy.auto_patch_in_use === _vm.$t('general.statuses.active') ? 'Deactivate' : 'Activate')+" Policy ")])],1):_vm._e(),(_vm.isManualApproval)?_c('v-list-item',{attrs:{"to":_vm.manualApprovalPath}},[_c('v-list-item-title',[_vm._v("Manage Approvals")])],1):_vm._e(),_c('ax-tooltip',{attrs:{"disabled":_vm.hasServerGroups,"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({},on),[(_vm.canExecutePolicy)?_c('v-list-item',{attrs:{"disabled":!_vm.hasServerGroups},on:{"click":function($event){return _vm.openModal(_vm.policy, 'runPolicy')}}},[_c('v-list-item-title',[_vm._v("Run Policy")])],1):_vm._e()],1)]}}])},[_c('span',[_vm._v("Associate a Group to Run this Policy")])]),_c('v-list-item',{attrs:{"to":{
        path: "/reports/activity-log",
        query: {
          o: _vm.orgId,
          policy_id: ("" + (_vm.policy.id)),
          policy_type_name: ("" + (_vm.policy.policy_type_name)),
          frompage: _vm.$route.path,
        },
      }}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('policy.viewPolicyActivityLog'))+" ")])],1),(_vm.canClonePolicy)?_c('v-list-item',{on:{"click":function($event){return _vm.openModal(_vm.policy, 'clonePolicy')}}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('policies.cloning.clonePolicy:callToAction'))+" ")])],1):_vm._e(),(_vm.canDeletePolicy)?_c('v-list-item',{on:{"click":_vm.removePolicy}},[_c('v-list-item-title',[_vm._v("Remove Policy")])],1):_vm._e()],1),_c('ax-modal',{attrs:{"persistent":""},model:{value:(_vm.showModal),callback:function ($$v) {_vm.showModal=$$v},expression:"showModal"}},[(_vm.activeModal === 'associateGroups')?_c('ax-assign-groups-modal',_vm._g({attrs:{"groups":_vm.groups,"policies":_vm.policies,"policy":_vm.selectedPolicy},on:{"close-modal":_vm.closeModal}},_vm.$listeners)):_vm._e(),(_vm.activeModal === 'toggleUsage')?_c('ax-toggle-policy-usage-modal',_vm._g({attrs:{"groups":_vm.groups,"policy":_vm.selectedPolicy},on:{"close-modal":_vm.closeModal}},_vm.$listeners)):_vm._e(),(_vm.activeModal === 'runPolicy')?_c('ax-run-policy-modal',{attrs:{"groups":_vm.groups,"policy":_vm.selectedPolicy},on:{"close-modal":_vm.closeModal}}):_vm._e(),(_vm.activeModal === 'clonePolicy')?_c('ax-clone-policy-modal',{attrs:{"policy":_vm.selectedPolicy},on:{"close-modal":_vm.closeModal}}):_vm._e(),(_vm.activeModal === 'deletePolicy')?_c('ax-remove-policy-modal',_vm._g({attrs:{"groups":_vm.groups,"policy":_vm.selectedPolicy},on:{"close-modal":_vm.closeModal}},_vm.$listeners)):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }