import { Ref, computed, ComputedRef } from '@vue/composition-api';
import { Route } from 'vue-router';

import { Organization } from '@ax/data-services-zone/models/organization';
import { usePermissionsForUserAndOrg } from '@ax/data-services-authorization/compositions/usePermissions';
import { User } from '@ax/data-services-user/models/user';
import { getRoleNameInOrg } from '@ax/data-services-user/business-logic';

import { consoleI18n } from '@ax/console-i18n';

import {
  getZoneNameOfClone,
  sortZoneOptions,
} from '../business-logic/clone-policy';
import {
  ClonedPolicyBatchResponse,
  ClonedPolicyByZone,
} from '../models/cloned-policy';
import { PolicyListItem } from '../models/list-policies';
import { getMappedPermissionByPolicyType } from '../business-logic/policy-permissions';
import { PolicyTypeName } from '../models/base-policy-dto';

type CloneLink = ClonedPolicyByZone & { zoneName?: string; to: Partial<Route> };
/**
 * @param zoneTargets the zones the policy was cloned into
 * @param cloneResult the response from POST /api/policies/:id/clone
 * @param routeName the name of the route to the policy form (`PolicyRouteNames.PolicyForm`)
 * @returns links to newly created clones of policies in their respective zones
 */
export function useClonedPolicyLinks(
  zoneTargets: Ref<Organization[]>,
  cloneResult: Ref<ClonedPolicyBatchResponse | undefined>,
  routeName: string,
): ComputedRef<CloneLink[]> {
  return computed(() =>
    zoneTargets.value?.length && cloneResult.value?.data.length
      ? cloneResult.value.data.map<CloneLink>((clone) => ({
          ...clone,
          zoneName: getZoneNameOfClone(clone, zoneTargets.value!),
          to: {
            name: routeName,
            params: { policyId: String(clone.policy_id) },
            query: { o: String(clone.org_id) },
          },
        }))
      : [],
  );
}

/**
 * @param policy the policy getting cloned
 * @param orgId the current organization's ID
 * @param user the current user
 * @returns the zones a user has permission to clone the policy into
 */
export function useCloneToZoneOptions(
  policy: PolicyListItem,
  orgId: Ref<number>,
  user: Ref<User>,
) {
  const { useZonesWithPermission } = usePermissionsForUserAndOrg(orgId, user);

  return computed(() => {
    const permissionQuery = getMappedPermissionByPolicyType(
      policy.policy_type_name as PolicyTypeName,
    );

    const zonesWithPermission = useZonesWithPermission(permissionQuery.Create);

    if (zonesWithPermission.value) {
      const [firstZone, ...remainingZones] = sortZoneOptions(
        orgId.value,
        zonesWithPermission.value,
      );

      // append "(current zone)" to the first zone's name if applicable
      return [
        {
          ...firstZone,
          name: getFirstZoneLabel(orgId.value, firstZone),
        },
        ...remainingZones,
      ];
    }

    return [];
  });
}

/**
 * Add the (Current Zone) label if this zone matches the current zone
 * @param orgId the current organization's ID
 * @param firstZone the first zone available to clone into
 * @returns the name of the first zone
 */
function getFirstZoneLabel(orgId: number, firstZone: Organization): string {
  if (firstZone.id === orgId) {
    return consoleI18n.t('policies.cloning.currentZone:labelWithValue', {
      name: firstZone.name,
    });
  }
  return firstZone.name;
}

/**
 * Get the payload to track when a user makes clones of a policy
 * @param orgId the current organization's ID
 * @param user the current User
 * @param selectedZones the zones the policy is getting cloned into
 * @param originalPolicy the policy being cloned
 * @returns the payload for analytics-tracking
 */
export function getTrackingPayloadForCloneCreation(
  orgId: number,
  user: User,
  selectedZones: Organization[],
  originalPolicy: PolicyListItem,
): {
  number_of_clones: number;
  user_role: string | undefined;
  policy_type: PolicyTypeName;
} {
  return {
    number_of_clones: selectedZones.length,
    user_role: getRoleNameInOrg(user, orgId),
    policy_type: originalPolicy.policy_type_name,
  };
}
