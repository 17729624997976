import { ZoneUUID } from '@ax/api-clients-accounts/models';
import { ClonedPolicyBatchResponse } from '../models/cloned-policy';
import { policiesClient } from './http-client';

const POLICIES_ENDPOINT = 'policies';

export function clonePolicyToZones(
  policyId: number,
  orgId: number,
  zoneIds: Array<ZoneUUID>,
): Promise<ClonedPolicyBatchResponse> {
  return policiesClient
    .post(
      `${POLICIES_ENDPOINT}/${policyId}/clone`,
      {
        target_zone_ids: zoneIds,
      },
      {
        params: {
          o: orgId,
          internal: 1,
        },
      },
    )
    .then(({ data }) => data);
}
