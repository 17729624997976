var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tw-text-right"},[(_vm.policy.server_group_count)?_c('ax-menu',{attrs:{"auto":"","bottom":"","open-on-hover":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('a',_vm._g({staticClass:"enlarged-link"},on),[_vm._v(_vm._s(_vm.policy.server_group_count))])]}}],null,false,1669974806)},[_c('v-list',{staticClass:"group-tooltip",attrs:{"max-height":"12rem"}},_vm._l((_vm.policyGroups),function(group){return _c('router-link',{key:group.id,staticClass:"tw-block tw-mx-4 tw-py-1",attrs:{"to":{
          name: 'groupEditor',
          query: {
            o: _vm.policy.organization_id,
            frompage: '/manage/groups',
          },
          params: {
            groupId: group.id,
          },
        }}},[_c('ax-device-group-item',{attrs:{"group":group}})],1)}),1)],1):_c('span',[_vm._v(_vm._s(_vm.policy.server_group_count))])],1)}
var staticRenderFns = []

export { render, staticRenderFns }