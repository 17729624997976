var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ax-table',_vm._g(_vm._b({attrs:{"footer-props":_vm.footerProps,"headers":_vm.headers,"active-headers":_vm.activeHeaders,"items":_vm.policies,"options":_vm.tableStateLocal,"search":_vm.search,"hide-default-footer":_vm.hideDefaultFooter,"column-select":_vm.columnSelect,"custom-sort":_vm.handleSort,"item-key":"id","no-data-text":"No policies"},on:{"update:options":function($event){_vm.tableStateLocal=$event}},scopedSlots:_vm._u([_vm._l((_vm.$scopedSlots),function(_,name){return {key:name,fn:function(slotData){return [_vm._t(name,null,null,slotData)]}}}),{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"tw-inline-block tw-leading-tight",attrs:{"title":item.name,"to":{
        name: _vm.PolicyRouteNames.PolicyForm,
        params: { policyId: item.id },
        query: {
          o: item.organization_id,
          frompage: _vm.$route.path,
        },
      }}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.device_filters_enabled",fn:function(ref){
      var item = ref.item;
return [_c('ax-chip',{attrs:{"mode":item.device_filters_enabled ? 'success' : 'informative'}},[_vm._v(" "+_vm._s(item.device_filters_enabled ? _vm.$t('general.phrases.yes') : _vm.$t('general.phrases.no'))+" ")])]}},{key:"item.server_count",fn:function(ref){
      var item = ref.item;
return [_c('span',{staticClass:"tw-whitespace-no-wrap tw-flex tw-items-center"},[_c('router-link',{staticClass:"enlarged-link",attrs:{"to":{
          path: "/devices",
          query: { o: item.organization_id, policyId: item.id },
        },"title":"View devices using this policy"}},[(item.device_filters_enabled)?_c('span',{staticClass:"tw-text-xs",attrs:{"data-test-id":"view-devices-btn-using-device-filters"}},[_vm._v(" View Devices ")]):[_vm._v(_vm._s(item.server_count))]],2),(item.device_filters_enabled)?_c('ax-tooltip',{attrs:{"max-width":"16rem","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('ax-button',{staticClass:"tw-ml-px",attrs:{"aria-label":"Why is there no device count available?","icon":"","x-small":""},on:{"click":on.mouseenter,"focus":on.mouseenter,"mouseenter":on.mouseenter,"mouseleave":on.mouseleave,"blur":on.mouseleave}},[_c('v-icon',{attrs:{"small":"","color":"primary"}},[_vm._v(_vm._s(_vm.mdiInformationOutline))])],1)]}}],null,true)},[_vm._v(" Due to the dynamic nature of device filters, the total number of devices for this policy may change. ")]):_vm._e()],1)]}},{key:"item.server_group_count",fn:function(ref){
        var item = ref.item;
return [_c('ax-group-tooltip',{attrs:{"groups":_vm.groups,"policy":item}})]}},{key:"item.auto_patch_in_use",fn:function(ref){
        var item = ref.item;
return [_c('span',{class:_vm.getStatusTextColor(item.auto_patch_in_use)},[_vm._v(" "+_vm._s(item.auto_patch_in_use)+" ")])]}},{key:"item.next_remediation",fn:function(ref){
        var item = ref.item;
return [_c('span',{staticClass:"tw-whitespace-no-wrap"},[_vm._v(" "+_vm._s(_vm._f("formatNextRemediation")(item.next_remediation))+" ")])]}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('ax-policy-action',_vm._g({attrs:{"policy":item,"policies":_vm.policies,"groups":_vm.groups}},_vm.$listeners))]}},{key:"item.configuration.os_family",fn:function(ref){
        var item = ref.item;
return [_c('v-lazy',[_c('div',{staticClass:"tw-flex tw-justify-center"},[_c('ax-os-icon',{attrs:{"os-family":item.configuration.os_family}})],1)])]}}],null,true)},'ax-table',_vm.$props,false),_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }