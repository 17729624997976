import { Organization } from '@ax/data-services-zone/models/organization';
import {
  ClonedPolicyBatchResponse,
  ClonedPolicyByZone,
} from '../models/cloned-policy';
import { PolicyListItem } from '../models/list-policies';

/**
 * Determine if the policy was cloned into the current zone, and return an array with the newly cloned policy.
 * This enables an up-to-date list-view of policies without additional GET /api/policies requests.
 * @param response the response from POST /api/policies/:id/clone
 * @param originalPolicy the policy getting cloned
 * @param zoneTargets the zones the policy was cloned into
 * @returns An array that contains the newly created policy, or an empty array.
 */
export function getNewPoliciesInCurrentZone(
  response: ClonedPolicyBatchResponse,
  originalPolicy: PolicyListItem,
  zoneTargets: readonly Organization[],
): PolicyListItem[] {
  const currentZoneId = zoneTargets.find(
    ({ id: orgId }) => orgId === originalPolicy.organization_id,
  )?.zone_id;

  const cloneInCurrentZone = response.data.find(
    (clone) => clone.zone_id === currentZoneId,
  );

  if (currentZoneId && cloneInCurrentZone?.policy_id) {
    return [
      {
        ...originalPolicy,
        name: response.policy_name,
        id: cloneInCurrentZone.policy_id!,
        server_groups: [],
        server_count: 0,
        configuration: {
          ...originalPolicy.configuration,
          auto_patch: false,
        },
      },
    ];
  }
  return [];
}

/**
 * Return an array of zones with the current organization/zone as the first item
 * @param orgId the current organization ID
 * @param zones the zones that might get selected as clone targets
 * @returns sorted `zones`
 */
export function sortZoneOptions(orgId: number, zones: readonly Organization[]) {
  const currentZoneIndex = zones.findIndex(({ id }) => id === orgId);
  if (currentZoneIndex > 0) {
    const shallowCopy = [...zones];
    const tmp = shallowCopy[0];
    shallowCopy[currentZoneIndex] = tmp;
    shallowCopy[0] = zones[currentZoneIndex];
    return shallowCopy;
  }
  return zones;
}

/**
 * Determine the name of the zone that a clone was created in
 * @param clone Cloning result data from `POST /api/policies/:id/clone`
 * @param zoneTargets the zones the policy was cloned into
 * @returns the name of the zone that the `clone` went into
 */
export function getZoneNameOfClone(
  clone: ClonedPolicyByZone,
  zoneTargets: Organization[],
) {
  return zoneTargets.find(({ zone_id }) => clone.zone_id === zone_id)?.name;
}
