import { consoleEnv } from '@ax/console-env';
import { createClient } from '@ax/api-clients-common/http-client-factory';

export const policiesClient = createClient({
  baseURL: consoleEnv.API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  // Support cross-domain proxy requests during development.
  // This has no effect on the same-origin requests that we use in production.
  withCredentials: consoleEnv.API_IS_PROXY,
});
